<section class="section proyects" id='proyects'>
    <div class="container">
        <div class="section-box">
            <div data-aos="fade-up" data-aos-duration="1000">
                <h3 class="section-title">
                    <span class='n-section-title'>03.</span> {{"FeatureProjects.Title" | translate}}
                </h3>
            </div>

            <div class="d-flex proyect-container" data-aos="fade-up" data-aos-duration="1000">
                <div class="container">              
                    <ul  class="cards">
                      <li *ngFor='let project of "FeatureProjects.Projects" | translate; let i = index' class="card">
                        <div> 
                            <a href={{project.Link}} target="_blank">
                          <div class="card-content">
                            <b><p>{{project.Title}}</p></b>
                          </div>
                             </a>  
                        </div>
                  
                        <div class="card-link-wrapper">
                        
                        </div>
                      </li>  
                    </ul>
                  </div>
            </div> 
            <div>
            </div>
        </div>
    </div>
</section>
