import { Component, HostListener, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  public MapVisible=true;
  public width=500;
  public height=400;
  constructor(
    public analyticsService: AnalyticsService
  ) { }

  @HostListener('window:resize', ['$event'])
  
  onResize(event) {
    if(window.innerWidth<788){
      this.MapVisible=false;
    }else{
      this.MapVisible=true;
      this.width = 500;
    }
  }
  
  ngOnInit(): void {
    
  }

}
