<section class="section banner">
    <div [@bannerTrigger] class="container">
        <div class="section-box-banner">
            <div class="content">
                <div>
              
                </div>
                <div class='banner-title'>
                    <h2>{{"Banner.Pretitle" | translate}}</h2>
                    <h3>{{"Banner.SubTitle" | translate}}</h3>
                </div>
                <div class='banner-description'>
                    <p [innerHTML]='"Banner.Description" | translate' class="mt-4"></p>
                </div>
            </div>
            <div class="div-btn-banner">
                <a (click)='scroll("contact")' class="main-btn">
                    {{"Banner.ActionBtn" | translate}}
                </a>
            </div>
        </div>
    </div>
</section>
