import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Router,ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  language: "tr" | "en";

  constructor(
    public translateService: TranslateService,
    private location: Location,
    private router : Router,
    private route:ActivatedRoute
  ) {}

  initLanguage(){    
  
    var lang = window.navigator.language; 
    this.translateService.addLangs(["tr", "en"])
    let language = navigator.language || (navigator as any).userLanguage;

    language = language.split("-").includes("tr") ? "tr" : "en"
    this.translateService.setDefaultLang(language);

    // Change the URL without navigate:
    //this.location.go("/tr")
    this.changeLanguage(language);
    //this.language=language
  }

  changeLanguage(language){
    this.translateService.setDefaultLang(language)
    this.location.go(language)
    this.language=language
  }
}
