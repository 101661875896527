<section class="section contact" id='contact'>
    <div class="container">
        <div class="section-box">
            <div class="col-12 col-md-12">
                <h3 class="e-font section-title">
                    <span class='code-font n-section-title'>04.</span> {{"Contact.Title" | translate}}
                </h3>
                <div class="row justify-content-around">
                    <div class="mt-4">
                        <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "contact", "email")' href="https://api.whatsapp.com/send?phone=905383605181&text=Merhaba" target="_blank" class="main-btn" data-aos="fade-up" data-aos-duration="1000">
                            <i class="fab fa-whatsapp"></i>
                        </a>
                    </div>
                    <div class="mt-4">
                        <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "contact", "email")' href="mailto:info@ahbalturizm.com" target="_blank" class="main-btn" data-aos="fade-up" data-aos-duration="1000">
                            {{"Contact.Btn" | translate}}
                        </a>
                    </div>
                </div>
                <div class="column m-5 flex-row">
                    <p> <i class="fa fa-phone"></i>   {{"Contact.Phone" | translate}}</p>
                    <a href="mailto:info@ahbalturizm.com" target="_blank" > <p> <i class="fa fa-envelope"></i>   {{"Contact.Mail" | translate}}</p> </a>
                    <a href="https://g.co/kgs/Yargscu" target="_blank" > <p> <i class="fa fa-map"></i>   {{"Contact.Adress" | translate}}</p> </a>
                </div>
              <div *ngIf="MapVisible" class="frame">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12051.115600163384!2d28.6274161!3d40.9644222!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b561cea25d3b3f%3A0xfc947a6bc1f4f409!2sAhbal%20Turizm!5e0!3m2!1str!2str!4v1709244147675!5m2!1str!2str" width={{width}} height={{height}} style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            </div>
        </div>
      
    </div>
</section>